

































































































































































































































































































































































































































































































































.login-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    background: url(../assets/KRECO.png) repeat 0px 0px; 
}
.ms-title {
    width: 100%;
    line-height: 50px;
    text-align: center;
    font-size: 10px;
    color: #2b85e4;
   /* border-bottom: 1px solid #ddd; */
}
.left{
    float:left
}
.right{
    float:right;
    margin-right:5px;
}
.ms-login {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 410px;
    margin: -250px 0 0 -205px;
    border-radius: 5px;
    background: rgb(245,245,248);//#f0f8ff;
    overflow: hidden;
} 
.ms-content {
    padding: 10px 30px;
}
.login-btn {
    height: 42px;
    display: flex;
    //justify-content: space-around;
    margin-top:20px;
}
.login-btn button {

    height: 36px;
    margin-bottom: 3px;
}
 
.logo {
  width: auto;
  height: 50px;
  margin-bottom:10px;
}
.remember {
    width: 100%;
    line-height: 20px;
    float:left;
    font-size: 8px;
    color: #2b85e4;
    font-weight: 500; 
}
.divcolor{
    background-color: #5cadff;
    width:2px;
}
